<template>
  <div class="item" :class="[activated && 'activated']">
    <div class="item-num" :class="[number > 99 && 'small']">
      <h1>{{ number }}</h1>
    </div>
    <div class="item-content">
      <div
        class="item-content-name"
        :title="name"
        @click="$emit('update:selectItem')"
      >
        <p>{{ limitCharacters(12, name) }}</p>
      </div>
      <div
        class="item-content-btn-delete"
        title="Eliminar"
        @click="$emit('update:deleteItem')"
      >
        <i class="far fa-trash-alt"></i>
      </div>
    </div>
  </div>
</template>

<script>
import limitCharacters from '@/modules/cms/helpers/limitCharacters';

export default {
  emits: ['update:selectItem', 'update:deleteItem'],
  props: {
    activated: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: 'Sin nombre',
    },
  },

  setup() {
    return {
      limitCharacters,
    };
  },
};
</script>
